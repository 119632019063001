<template>
  <div class="navigation-wrapper">
    <div ref="container" class="p-topics-slider keen-slider">
      <template v-for="(post, index) in posts" :key="post.id">
        <a
          class="p-topics-slider__card keen-slider__slide"
          :class="{ 'is-current': current === index }"
          :href="post.link"
        >
          <div class="p-topics-slider__thumbnail">
            <img
              :src="featuredImageData(post).url"
              :alt="featuredImageData(post).alt"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div class="p-topics-slider__main">
            <h2 class="p-topics-slider__title">{{ post.title.rendered }}</h2>
            <div
              class="p-topics-slider__text"
              v-html="post.excerpt.rendered"
            ></div>
          </div>
        </a>
      </template>
    </div>
    <div class="p-topics-slider__arrows">
      <div
        @click="prevSlide"
        :class="{
          arrow: true,
          'c-arrow c-arrow--left': true,
          'arrow--disabled': current === 0,
        }"
      ></div>
      <div
        v-if="slider"
        @click="nextSlide"
        :class="{
          arrow: true,
          'c-arrow c-arrow--right': true,
          'arrow--disabled':
            !slider ||
            current === (slider.track?.details?.slides.length || 0) - 1,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { useKeenSlider } from "keen-slider/vue";
import userEnv from "userEnv";
import { ref } from "vue";

import noImage from "../../../../img/common/noimage.png";

export default {
  name: "Voice",
  props: {
    courses: Array,
    postsPerPage: Number,
  },
  data() {
    return {
      url: userEnv.apiUrl,
      posts: "",
      errors: "",
      slider: null,
      current: 0,
    };
  },
  created() {
    this.getPosts();
  },
  methods: {
    getPosts() {
      Axios.get(this.url + "/wp-json/wp/v2/topics?_embed", {
        params: {
          per_page: this.postsPerPage,
          course: this.courses,
          exclude_ids: this.excludeIds,
        },
      })
        .then((response) => {
          this.posts = response.data;
          this.setupSlider();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    featuredImageData(post) {
      return {
        url: post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || noImage,
        alt:
          post._embedded?.["wp:featuredmedia"]?.[0]?.alt_text ||
          "Featured Image",
      };
    },
    setupSlider() {
      if (!this.slider) {
        return;
      }

      this.slider.container.addEventListener("mouseover", () => {
        this.clearNextTimeout();
      });
      this.slider.container.addEventListener("mouseout", () => {
        this.nextTimeout();
      });
      this.slider.on("dragStarted", this.clearNextTimeout);
      this.slider.on("animationEnded", this.nextTimeout);
      this.slider.on("updated", this.nextTimeout);
      this.$nextTick(() => this.slider.update());
    },
    clearNextTimeout() {
      window.clearTimeout(this.sliderTimeout);
    },
    nextTimeout() {
      window.clearTimeout(this.sliderTimeout);
      if (this.slider?.track?.details) {
        this.sliderTimeout = setTimeout(() => {
          this.slider.next();
          this.nextTimeout();
        }, 2000);
      }
    },
    nextSlide() {
      if (this.slider?.track?.details) {
        this.slider.next();
      }
    },
    prevSlide() {
      if (this.slider?.track?.details) {
        this.slider.prev();
      }
    },
  },
  setup() {
    const current = ref(0);
    const [container, slider] = useKeenSlider({
      initial: current.value,
      slideChanged: (s) => {
        current.value = s.track.details.rel;
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 3.128,
            spacing: 20,
          },
        },
        "(min-width: 1400px)": {
          slides: {
            perView: 3,
            origin: "center",
            spacing: 20,
          },
        },
      },
      loop: true,
      slides: {
        origin: "center",
        perView: 1,
      },
    });
    return { container, slider, current };
  },
  beforeDestroy() {
    if (this.slider) {
      this.slider.destroy();
    }
  },
};
</script>
